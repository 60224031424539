import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { GFLCharacter } from '../../../modules/exilium/components/exilium-character';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Button, Row, Col, Card, Alert } from 'react-bootstrap';
import { Box } from '../../../modules/common/components/box';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const ExiliumGuidesReroll: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page exilium-page gfl-guide'}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_reroll.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>GFL 2: Reroll Guide</h1>
          <h2>
            The best characters to reroll for in Girls' Frontline 2: Exilium
            (GFL 2).
          </h2>
          <p>
            Last updated: <strong>07/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Is it even worth to reroll?" />
        <p>
          The short answer is <strong>maybe</strong>. Whether rerolling in this
          game is worth it, depends on what type of player you are - below are
          some points you should consider before rerolling.
        </p>
        <h6>Why you should consider rerolling</h6>
        <ul>
          <li>
            Some of the characters available on the{' '}
            <strong>Standard character pool are obtainable for free</strong> if
            you play the game long enough - so eventually you will get them and
            their dupes (
            <GFLCharacter
              slug="sabrina"
              mode="inline"
              enablePopover
              showLabel
            />{' '}
            and{' '}
            <GFLCharacter
              slug="peritya"
              mode="inline"
              enablePopover
              showLabel
            />
            ). By rerolling you can avoid starting with a character that you
            will get for free either way.
          </li>
          <li>
            <strong>
              There's a huge disparity in strength between Standard characters
              obtained from the starter banner.
            </strong>{' '}
            On one side we have{' '}
            <GFLCharacter
              slug="qiongjiu"
              mode="inline"
              enablePopover
              showLabel
            />{' '}
            who is one of the best characters in CN nowadays (the game is 1 year
            old there), and on the other hand we have{' '}
            <GFLCharacter slug="vepley" mode="inline" enablePopover showLabel />{' '}
            who gets completely powercrept by a welfare unit{' '}
            <GFLCharacter slug="lotta" mode="inline" enablePopover showLabel />{' '}
            (who can be obtained on the first event for the Global release to
            make things even funnier).
          </li>
        </ul>
        <h6>Why rerolling may not be worth it</h6>
        <ul>
          <li>
            You <strong>can't just delete your account</strong> and start fresh
            instantly as the feature has a lengthy timer.
          </li>
          <li>
            Rerolling takes quite some time - you will need to do the tutorial
            and complete 1-4 to be unlock summoning feature.{' '}
            <strong>It takes around 10-12 minutes per account.</strong>
          </li>
          <li>
            The rate is not the best for rerollers. Even if we consider maxed
            out pre-registration rewards <strong>20 standard pulls</strong>,
            it's still only a <strong>0.6%</strong> chance of getting a SSR
            character per pull (on Beginner Banner) and <strong>0.3%</strong> on
            Standard Banner (because 0.3% is taken by Weapons). And if you want
            to aim for a specific Standard character, things get even worse.
          </li>
          <ul>
            <li>
              Seems there's additional 10 pulls waiting in the mail, so this
              means you reroll with 30 pulls,
            </li>
            <li>
              Though there's a chance we will get additional 10 pulls if the
              game hits top spots in Apple/Google Stores,
            </li>
          </ul>
          <li>
            A Beginner banner exists that guarantees a{' '}
            <strong>random SSR standard character within 50 pulls</strong> and
            the banner features 20% discount (so you need 40 Standard Summon
            Tickets to do 50 pulls).
          </li>
          <ul>
            <li>
              You can obtain more than 1 SSR character from the Beginner banner
              if you're lucky, but the pity will only trigger once. So if you
              already pulled a SSR before the 50th pull, it won't trigger again.
            </li>
          </ul>
          <li>
            Rerolling for the rate-up character is even harder as you get very
            little premium currency and it's enough to do a few pulls at most (4
            to be exact unless the codes give more).
          </li>
        </ul>
        <SectionHeader title="How to reroll" />
        <Alert variant="primary alert-red ">
          <p>
            It seems that{' '}
            <strong>
              Haoplay has removed both Guest Account and blocked the Salt Method
            </strong>
            , so you can't really reroll anymore on their servers. Both methods
            still work on Darkwinter servers.
          </p>
        </Alert>
        <h5>Reroll methods</h5>
        <p>You have two options when it comes to the method of rerolling:</p>
        <ul>
          <li>
            Using an <strong>Emulator to create multiple instances</strong>{' '}
            which will drastically increase the speed of rolling - and we
            suggest using <strong>BlueStacks</strong> for the process.
          </li>
          <ul>
            <li>
              Just keep in mind to create a master instance first and clone it
              without opening the game there - just to prevent account cookie to
              be created which will prevent you from multi-instancing.
            </li>
            <li>Also always login as Guest.</li>
          </ul>
          <li>
            Use the <strong>salt account email</strong> method, but keep in mind
            it's slower. Here's how it works:
          </li>
          <ul>
            <li>
              If your email is abc@gmail.com, add +numbers (anything is fine,
              but numbers make tracking the accounts easier) which makes it
              abc+1@gmail.com. Any email send to abc+1@gmail.com will to
              abc@gmail.com.
            </li>
          </ul>
        </ul>
        <h5>Reroll Process</h5>
        <ul>
          <li>
            Login through email option with salted email or use a Guest Account,
          </li>
          <li>Complete stages up to 1-4 where you will unlock gacha,</li>
          <li>Claim your mail rewards,</li>
          <li>Use all available codes (listed below),</li>
          <li>
            Use your pulls - and the options that you have there will be
            explained in the next section,
          </li>
          <li>Repeat step 1 to 5 until you are satisfied with your results,</li>
          <li>
            Keep track of that salted email and bind other credentials to the
            email.
          </li>
        </ul>
        <p>
          If you're planning to reroll using Emulator, we suggest using{' '}
          <strong>BlueStacks</strong>! You can download it from the link below
          and by doing so, support the website!
        </p>
        <div className="gfl-promo">
          <h4>Play & Reroll GFL 2 on PC</h4>
          <OutboundLink href="https://bstk.me/o485XScik" target="_blank">
            <Button variant="primary">Reroll now on BlueStacks</Button>
          </OutboundLink>
        </div>
        <SectionHeader title="Codes" />
        <p>
          Here's the list of active codes. Currently, we don't know what they
          give and we will update it post launch.{' '}
          <strong>How to redeem codes?</strong> Click the Settings button on the
          bottom left corner of the game's main interface, and click [Redeem
          Code] on the bottom right corner of the Personal Information page.
        </p>
        <p>Only codes that give summon currency are listed below.</p>
        <div className="codes">
          <Box isCentered>
            <p className="code">Darkwinter Servers</p>
            <ul>
              <li>
                <strong className="blue">ELMOLIVE</strong>
              </li>
              <li>
                <strong className="blue">OMNGKTWN</strong>
              </li>

              <li>
                <strong className="blue">GFL2GIFT</strong>
              </li>

              <li>
                <strong className="blue">1203GFL2</strong>
              </li>
            </ul>
          </Box>
          <Box isCentered>
            <p className="code">Haoplay Servers</p>
            <ul>
              <li>
                <strong className="blue">ELMOLIVE</strong>
              </li>
              <li>
                <strong className="blue">OMNGKTWN</strong>
              </li>
            </ul>
          </Box>
        </div>
        <p>
          There are more active codes, but only those give Collapse Pieces (used
          for summoning).
        </p>
        <SectionHeader title="Reroll paths" />
        <p>
          Now, you will have to choose two paths for the reroll, depending on
          how much free time you have and how much sanity are you willing to
          lose.
        </p>
        <Row xs={1} xl={2} xxl={2} className="reroll-paths">
          <Col>
            <div className="box">
              <div className="option hard">
                <p>Hell Path</p>
              </div>
              <div className="inside">
                <p className="time">
                  Difficulty: <strong>HELL</strong>
                </p>
                <p>
                  In this reroll path, you will aim to pull the character from
                  the rate-up banner using the premium currency you manage to
                  get during the reroll process (It won't be much - at most a
                  few pulls) and also get a random SSR character from Standard
                  Banner.
                </p>
                <hr />
                <h6>Process</h6>
                <ul>
                  <li>
                    Play through the tutorial according to the guide above to
                    unlock summoning feature,
                  </li>
                  <li>
                    Do the 4 pulls on the <strong>rate-up banner</strong> and
                    pray.
                  </li>
                  <ul>
                    <li>
                      Could be potentially more with codes and additional
                      rewards.
                    </li>
                  </ul>
                  <li>
                    If you got the rate-up, congrats, but it's not over yet!
                  </li>
                  <li>
                    To complete the Hell path you also want to get another SSR
                    character from the Standard Banner (NOT Beginner Banner), so
                    you can guarantee starting with 3 SSR characters (1x
                    rate-up, 1x from the 30 pulls on Standard and only then you
                    start pulling on Beginner to get the last random SSR
                    character).
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box">
              <div className="option easy">
                <p>Heaven Path</p>
              </div>
              <div className="inside">
                <p className="time">
                  Difficulty: <strong>Medium</strong>
                </p>
                <p>
                  This path ignores the rate-up banner and instead focuses
                  getting 1-4x SSR characters from the Beginner/Standard banner.
                </p>
                <hr />
                <h6>Process</h6>
                <ul>
                  <li>
                    Play through the tutorial according to the guide above to
                    unlock summoning feature,
                  </li>
                  <li>
                    Pull on the Standard Banner and try to get a SSR character
                    of your choice within the 30 pulls you get from pre-register
                    rewards.
                  </li>
                  <li>
                    If you obtain the SSR character you want, now you can switch
                    to the Beginner Banner - it has 20% discount for the first
                    50 pulls and you're guaranteed to obtain a SSR within that.
                    So you will start with 2 SSR characters - one you target and
                    one random.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <h5>What about Weapons?</h5>
        <p>
          You can get the Standard characters signature weapons from the
          Exchange Shop, so rolling specifically for them isn't advised. While
          it will take you some time to gather enough currency to do the
          exchange, it's better to be patient rather than having a weapon
          without the character that can use it.
        </p>
        <SectionHeader title="Video" />
        <p>
          Check this video if you're interested in optimizing the reroll process
          even more - 20 minutes to get 10x Rate-up tickets and 40 Standard
          Tickets.
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="UpMAb-jEnoA" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Standard SSR Characters" />
        <p>
          Here's the list of possible SSR Characters you can pull from the
          Beginner/Standard Banners (please note that{' '}
          <GFLCharacter slug="vepley" mode="inline" enablePopover showLabel />{' '}
          isn't available on the Beginner Banner as she's a guaranteed free
          reward from Beginner Missions):
        </p>
        <div className="employees-container-card-mode-gfl">
          <Card className="avatar-card">
            <GFLCharacter
              slug="mosin-nagant"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="tololo" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="sabrina" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="qiongjiu" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="peritya" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="vepley" mode="card" enablePopover showLabel />
          </Card>
        </div>
        <p>
          Now which of the 6 available characters is the best one? Let's go over
          each of them to show their good and bad sides!
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top SSR">
            <p className="name">Tololo [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="tololo"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                If you only care about easy progression, Tololo is the most
                recommended SSR T-Doll to get from Beginner Banner/Standard
                Banner. She excels at dealing direct and burst DPS, being able
                to gain 2 action phases compared to other units at the start of
                the battle. This means she can move and attack twice at the
                start of the battle, giving her huge flexibility and burst
                damage potential. She also possesses both debuff and CC
                abilities, forming a nice package that gives you a lot of
                options to deal with every situation on the battlefield.
              </p>
              <p>
                In Global she will shine brightly on release, but as the game
                progresses, Qiongjiu (and a few future DPS) will overcome her.
                So if you're interested in the 'long term' best choice and you
                don't mind suffering a bit early, you may check the next
                character on the list.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  She is a low cost operator that has 2 CD skills and 1 Ultimate
                  skill of 4 Confectance Index cost. All of her skills can be
                  used at the start of the battle.
                </li>
                <li>
                  Her base skills have good damage and get better when she has
                  buffs. Her passive has an innate Critical Rate and Critical
                  Damage permanent boost.
                </li>
                <li>
                  Her Neural Helix keys help enhance and make her better
                  throughout Early, Mid and Late stages.
                </li>
                <li>
                  She isn’t a dupe reliant character - with just having 1 dupe
                  being enough to make her strong.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Needs an allied Hydro unit to continue to stack her permanent
                  Critical Rate and Critical Damage boost. Hydro T-Dolls are
                  heavily limited with only Sabrina and Colphne available.
                </li>
                <li>
                  Deals Hydro Element which gets replaced by Electric and Freeze
                  Phase weakness later in the stages.
                </li>
                <li>
                  Her damage lags behind other DPS in the current CN meta.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Qiongjiu [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="qiongjiu"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Qiongjiu excels at doing single-target damage, providing
                indirect DPS to the team through Action Support. And since she
                can deal both Burn damage and Physical damage, she's more
                versatile and can find a spot in both single-target-focused
                teams and Burn teams (her preferred option). She will make your
                Weekly Bosses a breeze once you learn how to properly use her.
              </p>
              <p>
                <strong>Important!</strong> Initially, on the Global server, she
                will struggle a bit as she's not really good in the
                early/mid-game and is not beginner-friendly as she is reliant on
                teammates to work. Still, in CN, she's currently one of the
                strongest DPS characters and considered the most future-proof
                Standard character who gets even stronger with dupes.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Top tier single target damage output - she's still meta even
                  now in CN because of that,
                </li>
                <li>Provides a steady amount of DPS through Action Support.</li>
                <li>
                  Her Action Support has low cooldowns allowing her to
                  continuously provide indirect DPS unlike other T-Dolls’ Action
                  Support.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Not beginner friendly - requires understanding of combat
                  mechanics.
                </li>
                <li>
                  Requires Level 30+ to become stronger as her strongest Neural
                  Helix keys are locked behind higher levels.
                </li>
                <li>
                  Heavily Dupe reliant, as her best potential is locked behind
                  the Fortification Segment.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Sabrina [Tank]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="sabrina"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Sabrina is a hybrid DPS/Tank who provides defensive buffs to
                allies and inflicts various debuffs on enemies. She can also
                Taunt them, which is a very useful ability to save allies,
                especially in PVP. Her skills are more AOE-focused, so she
                thrives in normal stages but struggles against bosses.
              </p>
              <p>
                She can manipulate the battlefield by creating and destroying
                cover, which can drastically change the flow of battle, allowing
                her to protect an ally or mess up the enemy's formation. Sadly,
                she's quite dupe-reliant - they are needed to fix some of the
                issues in her kit, but she still works decently well without
                them. As long as you can work around them that is. Thankfully,
                she can be obtained from the Weekly Boss Mode (1 copy every 2
                months roughly) so it's only a matter of time before you unlock
                her dupes.
              </p>
              <p>
                <strong>Important!</strong> Sabrina and her dupes can be
                obtained for free from Weekly Boss Mode (1 copy every 2 months
                roughly)
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Has 2 good AoE skills with a good amount of damage being dealt
                  as well as providing terrain that slows enemy units. Terrain
                  can be further used by Sabrina to remove said terrain and deal
                  extra damage to enemy units.
                </li>
                <li>
                  Can destroy any destructible cover easily and her Meal
                  Preparation skill is not impeded by cover.
                </li>
                <li>
                  Can also self heal her Stability Index without the need of any
                  support T-Doll.
                </li>
                <li>
                  Synchronizes well with Tololo. Her passive allows her to apply
                  a Hydro Element debuff to a target, allowing Tololo to exploit
                  Phase weakness as well providing Tololo her permanent Critical
                  Rate and Critical Damage permanent boost stacks.
                </li>
                <li>
                  Her Counterattack can deal 4 stability damage and her self
                  buff increases her damage based on her Defense.
                </li>
                <li>
                  She can be farmed long term as her dupe tokens can be acquired
                  from the weekly Boss Battle mode.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Semi Dupe reliant as her Fortification Segment 5 is highly
                  recommended to get which requires 5 dupes. This makes it so
                  she can use her ultimate skill and attack, increasing her
                  flexibility as a Hybrid DPS/Tank.
                </li>
                <li>Her best neural helix key is unlocked at Level 60.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Mosin Nagant [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="mosin-nagant"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Mosin-Nagant excels at single target long-range DPS, dealing
                Electric element damage, providing Action Support, and applying
                CC on enemies. She's very slow though, so make sure you position
                her well. Keep in mind that she's very reliant on attacking
                enemies without Stability and will run out of skill points if
                she doesn't do it often.
              </p>
              <p>
                Sadly, while in Global she will work decently well early on, in
                the current CN meta she fell a bit in the pecking order due to
                the fact that her assist only triggers against Stability broken
                enemies. With some bosses having tons of Stability, it messes up
                with her kit - as she needs to assist consistently to deal
                damage and for her to work. While this is partially fixed once
                you obtain her dupes, at V0, it makes her hard to use and very
                situational.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Can stun enemy targets via Paralysis and Shock.</li>
                <li>
                  Can change her skills to do Electric attacks in order to
                  exploit her Stun potential without wasting a turn.
                </li>
                <li>
                  Has decent Neural Helix keys to help boost her support and
                  functionality.
                </li>
              </ul>
              <ul className="cons">
                <li>Dupe reliant - requires dupes to become more effective.</li>
                <li>
                  Her synchronization with other Electric Element T-Dolls is
                  locked behind Fortification Segment - Six.
                </li>
                <li>
                  Her Action Support requires targets to enter Stability Break
                  compared to other Action Supports.
                </li>
                <li>Very low mobility.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="silver"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Peritya [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="peritya"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Peritya excels at doing a lot of AoE Corrosion Damage. Her AoE
                potential is unmatched, making her the core member of any
                AoE-focused team, but it comes at a cost - being way below
                average in everything else. She's also quite dupe and
                team-reliant, making her hard to use in the early/mid game.
              </p>
              <p>
                Peritya is not recommended for your reroll target in Global due
                to her low base damage and lack of viability outside of her AoE
                attacks. That's why she will be kinda underwhelming for the
                Global players, but she becomes better with future units
                available in CN (and once you obtain some dupes for her). Klukai
                and Mechty are two characters that make her viable and worth
                using - so if you love Peritya and want to make her work in the
                future, make sure to grab both! With them, she will become
                second best Standard character, right behind Qiongjiu.
              </p>
              <p>
                <strong>Important!</strong> Peritya and her dupes can be
                obtained for free from Mystery Box gacha in Dispatch.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Can dispel buffs from enemies.</li>
                <li>
                  Can pull enemy targets closer to each other for easier AoE
                  attacks.
                </li>
                <li>Great AoE damage potential.</li>
              </ul>
              <ul className="cons">
                <li>
                  Heavily Dupe reliant, as her best potential is locked behind
                  the Fortification Segment.
                </li>
                <li>
                  Takes longer to charge up her Ultimate skill unlike other
                  T-Dolls and has a high Confectance Index cost. Her ultimate
                  skill also requires her to be in the middle of a large group
                  of enemies making it a high risk high reward skill.
                </li>
                <li>
                  Requires Level 30+ to become stronger as her strongest Neural
                  Helix keys are locked behind higher levels.
                </li>
                <li>
                  Low damage on her basic attacks, which lowers her
                  effectiveness with Action Support. Action Support also
                  requires allied units AoE attacks to trigger, further
                  hampering her effectiveness.
                </li>
                <li>
                  Her best partners in crime - Klukay and Mechty - are not
                  available on Global yet.
                </li>
                <li>Due to her AOE nature, she struggled in Boss content.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Vepley [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="vepley"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Vepley is a highly mobile character who excels in knocking back
                enemies and instantly killing them when they are pushed off the
                map. She has a mix of single-target and AoE skills, making her
                pretty versatile, but because of her low range and weak Neural
                Helix Keys, she struggles to find a spot for herself in the
                meta. Her biggest weakness is the inability to move after
                attacking which combined with the low range, often makes her
                vulnerable - without proper support, this makes her the
                reincarnation of Leeroy Jenkins in GFL2.
              </p>
              <p>
                For reroll on Global, Vepley is not recommended due to her low
                range and lack of viability outside of Boss Fight mode. To make
                things worse, she gets 'powercrept' by a welfare character
                available in the first patch on Global - Lotta.
              </p>
              <p>
                <strong>Important!</strong> Vepley can be obtained for free from
                the Beginner Mission event.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Can cleanse Buff off of enemy targets.</li>
                <li>Can knock back enemy targets.</li>
                <li>Very mobile.</li>
              </ul>
              <ul className="cons">
                <li>
                  Semi Dupe reliant, as her best potential is locked behind the
                  Fortification Segment.
                </li>
                <li>
                  Relatively weak Neural Helix keys, making her a niche T-Doll
                  that is primarily used in Boss Fight mode.
                </li>
                <li>
                  Has low range which makes it harder for her to deal damage
                  compared to other DPS units.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Reroll value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
        <p>Reroll value explanation:</p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="gold" /> -
            The best you can go for,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="silver" /> -
            The runner-up who have some good sides, but also bad sides,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="bronze" /> -
            While they aren't strong enough to be worth rerolling for, they
            still have some good things about them that make them worth using -
            so they deserve a bronze award!
          </li>
        </ul>
        <SectionHeader title="Rate-up SSR Characters" />
        <div className="employees-container-card-mode-gfl">
          <Card className="avatar-card">
            <GFLCharacter slug="suomi" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="ullrid" mode="card" enablePopover showLabel />
          </Card>
        </div>
        <p>
          During the 1.0 patch on Global there will be 2 SSR characters
          available in their limited banners.{' '}
          <GFLCharacter slug="suomi" mode="inline" enablePopover showLabel />{' '}
          and{' '}
          <GFLCharacter slug="ullrid" mode="inline" enablePopover showLabel />{' '}
          will be available at the same time from 3rd December to 25th December.
          Out of the two, <strong>Suomi is 100% more worth</strong> and if you
          want to learn more, check their comparison below.
        </p>
        <div className="reroll-char-info reroll first">
          <div className="top SSR">
            <p className="name">Suomi [Sustain]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="suomi"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Every game has a character like Suomi - a character that can do
                everything and anything and do it so well, that nobody can even
                come close to her greatness. Suomi is a Shielder / Healer /
                Buffer / Debuffer hybrid - yes, four roles - who can trivialize
                a lot of content by simply existing. Her Shields are so chunky,
                that they basically double the HP of everyone in the team,
                allowing for a more aggressive playstyle and giving you some
                room for errors. She can also dispel the pesky debuffs from
                allies, quickly negating enemy advantage over them. Honestly,
                listing everything she can do would turn this mini-intro into a
                full-blown essay, so if you have to ask yourself, if Suomi is
                able to do something, there's a high chance she does.
              </p>
              <p>
                Even nowadays in the CN servers, she's considered the best
                Sustain/Support in the game so grab a copy or two of her (as her
                S1 amplifies her passive Healing that is honestly the weakest
                part of her kit) and just enjoy progressing through the game
                without any struggles.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Provides insane shields to all allies.</li>
                <li>
                  Has healing built into her passive and can further increase
                  her healing by having just 1 dupe.
                </li>
                <li>
                  Provides a good amount of buffs too - so she has the trifecta:
                  Shielding, Healing and Buffs.
                </li>
                <li>Her base kit is decent enough without needing dupes.</li>
              </ul>
              <ul className="cons">
                <li>She has no cons - she is a super support.</li>
              </ul>
            </div>
            <div className="ratings">
              <p>Limited pull value:</p>
              <p className="with-reward">
                <FontAwesomeIcon icon={faTrophy} width="30" className="gold" />
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top SSR">
            <p className="name">Ullrid [DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <GFLCharacter
                  slug="ullrid"
                  mode="card"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Ullrid is a Vanguard T-Doll that can do both ranged damage and
                melee damage. She's the only SSR Sword wielder in the game
                currently, but sadly that's the only unique thing about her.
                Even her Stealth ability doesn't save her, as it can easily be
                broken by AoE damage. Without her Mod Key, her damage is very
                underwhelming and she often just rushes toward the enemy
                position to tickle them and then dies before she can retreat.
                With the Mod Key, her damage output improves, but it still isn't
                enough to catch up to other, stronger damage dealers - who also
                lack her weaknesses, making Ullrid quite useless.
              </p>
              <p>
                Ullrid is also very dupe-reliant as they actually fix some of
                the issues of her kit, but pulling for dupes for a limited SSR
                unit only for her to become 'average' is honestly just throwing
                money away. Better save your pulls and gems for other
                characters.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Has stealth mechanic.</li>
                <li>Good mobility.</li>
              </ul>
              <ul className="cons">
                <li>
                  Heavily Dupe reliant, as her best potential is locked behind
                  the Fortification Segment.
                </li>
                <li>
                  Stealth doesn’t do anything aside from making her
                  untargetable. Stealth can still be broken by AoE attacks.
                </li>
                <li>
                  Not beginner friendly, requires understanding of combat
                  mechanics.
                </li>
                <li>
                  Requires Level 30+ to become stronger as her strongest Neural
                  Helix keys are locked behind higher levels.
                </li>
                <li>
                  Low synergy in the base kit resulting in lower performance
                  than other T-Dolls.
                </li>
              </ul>
            </div>
            <div className="ratings">
              <p>Limited pull value:</p>
              <p className="with-reward">
                <FontAwesomeIcon
                  icon={faTrophy}
                  width="30"
                  className="bronze"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesReroll;

export const Head: React.FC = () => (
  <Seo
    title="Reroll Guide (GFL 2) | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="The best characters to reroll for in Girls' Frontline 2: Exilium (GFL 2)."
    game="exilium"
  />
);
